const changeService = () => {

    const tabsParent = document.querySelector('.services__tabs');
    const tabs = document.querySelectorAll('.services__tab');
    const tabsContent = document.querySelectorAll('.services__content-item');
    const listItems = document.querySelectorAll('.services__content-list-item');
    const buttonLinks = document.querySelectorAll('.services__content-btn');
    
    function hideTabsContent() {
        tabsContent.forEach(item => {
            item.style.display = 'none';
            item.classList.remove('services__content-item--visible');
        });
    
        tabs.forEach(item => {
            item.classList.remove('services__tab--active');
        });
    };
    
    function showTabContent(index = 0) {
        tabsContent[index].style.display = 'flex';
        setTimeout(function () {
            tabsContent[index].classList.add('services__content-item--visible');
        }, 50);
        tabs[index].classList.add('services__tab--active');
        showFirstOfListServices(0);
    };
    
    function showFirstOfListServices() {
        document.querySelectorAll('.services__content-right').forEach(function (element) {
            element.classList.remove('services__content-right--visible');
            element.style.display = 'none'; 
        });
    
        const firstListItems = document.querySelectorAll('.services__content-list-item--first');
    
        firstListItems.forEach(firstListItem => {
            const itemId = firstListItem.id;
            const contentRight = document.querySelector('.services__content-right[data-service="' + itemId + '"]');
            firstListItem.classList.add('services__content-list-item--color');
    
            if (contentRight) {
                contentRight.style.display = 'block'; 
                contentRight.classList.add('services__content-right--visible');
            }
        });
    
        document.querySelectorAll('.services__content-list-item').forEach(function (element) {
            if (!element.classList.contains('services__content-list-item--first')) {
                element.classList.remove('services__content-list-item--color');
            }
        });
    };
    
    hideTabsContent();
    showTabContent();
    
    tabsParent.addEventListener('click', (e) => {
        const target = e.target;
    
        if (target && target.classList.contains('services__tab')) {
            tabs.forEach((item, i) => {
                if (target == item) {
                    hideTabsContent();
                    showTabContent(i);
                }
            });
        }
    });
    
    function handleListItemClick() {
        listItems.forEach((item, index) => {
            item.addEventListener('click', function () {
                const itemId = this.id;
                const contentRight = document.querySelector('.services__content-right[data-service="' + itemId + '"]');
                const dataLink = this.getAttribute('data-link');
    
                if (itemId === contentRight.dataset.service) {
                    listItems.forEach(item => {
                        item.classList.remove('services__content-list-item--color');
                    });
    
                    document.querySelectorAll('.services__content-right').forEach(function (element) {
                        element.classList.remove('services__content-right--visible');
                        element.style.display = 'none';
                    });
    
                    this.classList.add('services__content-list-item--color');
    
                    contentRight.style.display = 'block';
                    setTimeout(function () {
                        contentRight.classList.add('services__content-right--visible');
                    }, 50);
    
                    buttonLinks[index].parentElement.href = dataLink || '';
                }
            });
        });
    }
    
    handleListItemClick();
    
};

export default changeService;