const tabs = (tabsParentClass, tabsClass, tabsContentClass, display, visibleClass, activeClass, tabClass) => {

    const tabsParent = document.querySelector(tabsParentClass);
    const tabs = document.querySelectorAll(tabsClass);
    const tabsContent = document.querySelectorAll(tabsContentClass);

    function hideTabsContent() {
        tabsContent.forEach(item => {
            item.style.display = 'none';
            item.classList.remove(visibleClass);
        });

        tabs.forEach(item => {
            item.classList.remove(activeClass);
        });
    }

    function showTabsContent(i = 0) {
        tabsContent[i].style.display = display;
        setTimeout(function () {
            tabsContent[i].classList.add(visibleClass);
        }, 50);
        tabs[i].classList.add(activeClass);
    }

    hideTabsContent();
    showTabsContent();

    tabsParent.addEventListener('click', (e) => {
        const target = e.target;

        if (target && target.classList.contains(tabClass)) {
            tabs.forEach((item, i) => {
                if (target == item) {
                    hideTabsContent();
                    showTabsContent(i);
                }
            });
        }
    });

};

export default tabs;