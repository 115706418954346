import $ from 'jquery';

const accordion = (itemClass, contetClass, activeClass) => {

    const isWhyAccordionItem = $(itemClass).hasClass('why__accordion-item');

    if (isWhyAccordionItem) {
        $(itemClass).not(':first').find(contetClass).hide();
        $(itemClass + ':first').addClass('rotate-arrow');
    } else {
        $(contetClass).hide();
    }

    $(itemClass).click(function () {

        $(itemClass).not(this).find(contetClass).slideUp();
        $(this).find(contetClass).slideToggle();
        $(itemClass).not(this).removeClass(activeClass);
        $(this).toggleClass(activeClass);

    });

}

export default accordion;