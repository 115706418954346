import $ from 'jquery';

const popup = (data, modal, close) => {
    $(data).on('click', function () {
        $(modal).fadeIn('slow');
    });

    $(close).on('click', function () {
        $(modal).fadeOut('slow');
    });

    $('body').on('click', function (event) {
        if (!$(event.target).closest(modal).length && !$(event.target).is(data)) {
            $(modal).fadeOut('slow');
        }
    });
};

export default popup;