import sliders from "./modules/sliders";
import tabs from "./modules/tabs";
import accordion from "./modules/accordion";
import menu from "./modules/menu";
import popup from "./modules/popup";
import scrollUp from "./modules/pageUp";
import changeService from "./modules/changeService";

window.addEventListener('DOMContentLoaded', () => {

    try {
        sliders();
    } catch (error) { }

    try {
        tabs('.procedure__tabs', '.procedure__tab', '.procedure__content-item', 'block', 'procedure__content-item--visible', 'procedure__tab--active', 'procedure__tab');
    } catch (error) { }

    try {
        accordion('.why__accordion-item', '.why__accordion-content', 'rotate-arrow');
    } catch (error) { }

    try {
        accordion('.faq__accordion-item', '.faq__accordion-content', 'minus');
    } catch (error) { }

    try {
        menu();
    } catch (error) { }

    try {
        popup('[data-modal=popup]', '#modal', '.modal__close');
    } catch (error) { }

    try {
        popup('[data-modal=certificates]', '#certificates-modal', '.modal__close');
    } catch (error) { }

    try {
        popup('[data-modal=docs]', '#docs-modal', '.modal__close');
    } catch (error) { }

    try {
        scrollUp();
    } catch (error) { }

    try {
        changeService();
    } catch (error) { }

});







