const menu = () => {

    const hamburger = document.querySelector('.hamburger');
    const menu = document.querySelector('.header__bottom');
    const menuItem = document.querySelectorAll('.header__menu-link');
    const menuBtn = document.querySelector('.header__menu-btn'); 
    
    function toggleMenu() {
        hamburger.classList.toggle('hamburger--active');
        menu.classList.toggle('header__bottom--active');
    
        if (menu.classList.contains('header__bottom--active')) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }
    
    function close() {
        hamburger.classList.remove('hamburger--active');
        menu.classList.remove('header__bottom--active');
        document.body.style.overflow = 'unset';
    }
    
    hamburger.addEventListener('click', toggleMenu);
    
    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            toggleMenu();
            close();
        });
    });
    
    menuBtn.addEventListener('click', () => {
        toggleMenu();
        close();
    });
    

};

export default menu;