import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const sliders = () => {

    const feedbackSlider = new Swiper('.reviews__slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        navigation: {
            nextEl: '.reviews__slider-next',
            prevEl: '.reviews__slider-prev',
        },
        pagination: {
            el: '.reviews___slider-dots',
            clickable: true,
        },
        breakpoints: {
            1200: {
                slidesPerView: 2,
                slidesPerGroup: 1,
                spaceBetween: 60,
            },
        }
    });

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 992) {
        const advantagesSlider = new Swiper('.advantages__slider', {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.advantages__slider-next',
                prevEl: '.advantages__slider-prev',
            },
            pagination: {
                el: '.advantages__slider-dots',
                clickable: true,
            },
        });
    } 

    
    const articlesSlider = new Swiper('.articles__slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.articles__slider-next',
            prevEl: '.articles__slider-prev',
        },
        pagination: {
            el: '.articles___slider-dots',
            clickable: true,
        },
        breakpoints: {
            1201: {
                slidesPerView: 2,
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
        }
    });

    const certificatesSlider = new Swiper('.certificates__slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.certificates__slider-dots',
            clickable: false,
        },
        navigation: {
            nextEl: '.certificates__slider-next',
            prevEl: '.certificates__slider-prev',
        },
    });



}

export default sliders;