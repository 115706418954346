import $ from 'jquery';

const scrollUp = () => {

    $(window).scroll(function () {
        if ($(this).width() > 541) { 
            if ($(this).scrollTop() > 1600) {
                $('.pageup').fadeIn();
            } else {
                $('.pageup').fadeOut();
            }
        } else {
            $('.pageup').fadeOut();
        }
    });
    
};

export default scrollUp;